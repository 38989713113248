import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const Profile = () => {
  let [tabtype, settabtype] = useState(0);
  const [data, setdata] = useState([]);
  const params = useParams();

  useEffect(() => {
    getData("");
  }, []);

  const getData = async (date) => {
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    console.log(params.uuid);
    await fetch(BASEURL + `getUserDetails/${params.uuid}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        type: 0,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdata(res.userdata);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
          <div className="intro-y box mt-5">
            <div className="relative flex items-center">
              <h2 className="text-lg font-medium mr-auto p-3">User Details</h2>
            </div>
            <div
              className="p-5 border-t border-slate-200/60 dark:border-darkmode-400"
              style={{ cursor: "pointer" }}
            >
              <div
                className={
                  tabtype === 0
                    ? "flex items-center text-primary font-medium  mt-3"
                    : "flex items-center mt-3"
                }
                onClick={() => settabtype(0)}
              >
                <FaUser className="mr-2" /> Basic{" "}
              </div>
              <div
                className={
                  tabtype === 1
                    ? "flex items-center text-primary font-medium  mt-3"
                    : "flex items-center mt-3"
                }
                onClick={() => settabtype(1)}
              >
                <FaUser className="mr-2" /> Personal{" "}
              </div>
              <div
                className={
                  tabtype === 2
                    ? "flex items-center text-primary font-medium  mt-3"
                    : "flex items-center mt-3"
                }
                onClick={() => settabtype(2)}
              >
                <FaUser className="mr-2" /> Organization{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
          {tabtype === 0 ? (
            <div className="intro-y box lg:mt-5">
              <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">Basic Details</h2>
              </div>
              <div className="p-5">
                <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
                  <div className="flex-1 mt-6 xl:mt-0">
                    <div className="grid grid-cols-12 gap-x-5">
                      <div className="col-span-12 2xl:col-span-6">
                        <div>
                          <label
                            for="update-profile-form-1"
                            className="form-label"
                          >
                            Name
                          </label>
                          <input
                            id="update-profile-form-1"
                            type="text"
                            className="form-control"
                            value={data.length > 0 && data[0].name}
                            disabled
                          />
                        </div>
                        <div className="mt-3">
                          <label
                            for="update-profile-form-2"
                            className="form-label"
                          >
                            Email ID
                          </label>
                          <input
                            id="update-profile-form-6"
                            type="text"
                            className="form-control"
                            value={data.length > 0 && data[0].email}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-12 2xl:col-span-6">
                        <div className="mt-3 2xl:mt-0">
                          <label
                            for="update-profile-form-3"
                            className="form-label"
                          >
                            Username
                          </label>
                          <input
                            id="update-profile-form-6"
                            type="text"
                            className="form-control"
                            value={data.length > 0 && data[0].username}
                            disabled
                          />
                        </div>
                        <div className="mt-3">
                          <label
                            for="update-profile-form-4"
                            className="form-label"
                          >
                            Phone Number
                          </label>
                          <input
                            id="update-profile-form-6"
                            type="text"
                            className="form-control"
                            value={data.length > 0 && data[0].contact_no}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-span-12">
                        <div className="mt-3">
                          <label
                            for="update-profile-form-5"
                            className="form-label"
                          >
                            Address
                          </label>
                          <textarea
                            id="update-profile-form-5"
                            className="form-control"
                            disabled
                          >
                            {data.length > 0 ? data[0].permanent_address : null}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {tabtype === 1 ? (
            <div className="intro-y box mt-5">
              <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">
                  Personal Information
                </h2>
              </div>
              <div className="p-5">
                <div className="grid grid-cols-12 gap-x-5 text-left">
                  <div className="col-span-12 xl:col-span-6">
                    <div>
                      <label for="update-profile-form-6" className="form-label">
                        Gender
                      </label>
                      <select
                        id="update-profile-form-8"
                        className="form-select"
                        disabled
                      >
                        <option value={0}>Male</option>
                        <option value={1}>Female</option>
                        <option value={2}>Other</option>
                      </select>
                    </div>
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Blood Group
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={data.length > 0 ? data[0].blood_group : null}
                        disabled
                      />
                    </div>
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Date of Birth
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={
                          data.length > 0
                            ? format(new Date(data[0].dob), "yyyy-MM-dd")
                            : null
                        }
                        disabled
                      />
                    </div>
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Date of Joining
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={
                          data.length > 0
                            ? format(
                                new Date(data[0].date_of_joining),
                                "yyyy-MM-dd"
                              )
                            : null
                        }
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Date of Exit
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={
                          data.length > 0 &&
                          data[0].date_of_exit === "0000-00-00"
                            ? format(
                                new Date(data[0].date_of_exit),
                                "yyyy-MM-dd"
                              )
                            : null
                        }
                        disabled
                      />
                    </div>
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Height
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={data.length > 0 ? data[0].height : null}
                        disabled
                      />
                    </div>
                    <div className="mt-3">
                      <label for="update-profile-form-7" className="form-label">
                        Weight
                      </label>
                      <input
                        id="update-profile-form-7"
                        type="text"
                        className="form-control"
                        value={data.length > 0 ? data[0].weight : null}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {tabtype === 2 ? (
            <div className="intro-y box mt-5">
              <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                <h2 className="font-medium text-base mr-auto">
                  Organization Details
                </h2>
              </div>
              <div className="p-5">
                <div className="grid grid-cols-12 gap-x-5 text-left">
                  <div className="col-span-12 xl:col-span-6">
                    <div>
                      <label for="update-profile-form-6" className="form-label">
                        Role
                      </label>
                      <select
                        id="update-profile-form-8"
                        className="form-select"
                        value={data.length > 0 ? data[0].type : null}
                        disabled
                      >
                        <option value={2}>Employee</option>
                        <option value={1}>Manager</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-12 xl:col-span-6">
                    <div>
                      <label for="update-profile-form-6" className="form-label">
                        Working Shifts*
                      </label>
                      <select
                        id="update-profile-form-8"
                        className="form-select"
                        disabled
                      >
                        <option selected>09:00 - 18:00</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Profile;
