import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "./BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const Departments = () => {
  const [userdata, setuserdata] = useState([]);
  // const [dept, setdept] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);
  const [add, setadd] = useState(2);

  const [attdata, setattdata] = useState(null);
  
  // const [getdata, setgetdata] = useState([]);
  const [data, setdata] = useState({
    id: "",
    punchtype: 1,
    start_time: "",
    end_time: "",
    description: "",
    date: "",    
  });

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState({
    type: "",
    msg: "",
  });

  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

  useEffect(() => {
    setadd(JSON.parse(localStorage.getItem("isAuth")).type);
    const endOffset = itemOffset + 20;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 20));
    getData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 20;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 20));
  }, [itemOffset]);

  
  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getTickets`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 0,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          
          let arr = [];
          let arr1 = [];
          for (const key in res.data) {
            arr.push(key);
            if (key < 20) {
              arr1.push(key);
            }
          }

          setitems(arr);
          setCurrentItems(arr1);
          setPageCount(Math.ceil(arr.length / 20));


          setattdata(res.data);
          setbkpdata(res.data);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };


  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setuserdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 20));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 20) % matches.length;
      setItemOffset(newOffset);
      setuserdata(matches);
      const endOffset = itemOffset + 20;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 20));
    }
  };

  const statuschange = (id,status,tickettype) => {
    let finalstatus = "";
    if(status===1){ finalstatus="Approve"} else {finalstatus="Reject";}
    swal({
      title: finalstatus,
      text: `Please confirm if you want to Change Ticket Status?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `ticket-status-change`, {
          method: "post",
          body: JSON.stringify({
            id,status,tickettype
          }),
          headers: myHeaders,         
        })
          .then((response) => response.json())
          .then((res) => {
            getData();
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 20) % items.length;

    setItemOffset(newOffset);
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">All Tickets</h2>
        {/* {master === 1 && 
          <button
            type="button"
            onClick={() => addorg()}
            className="btn btn-primary w-50 mr-2"
          >
            Add Organization
          </button>
        } */}
        {/* {add === 0 ? ( */}
          {/* <button
            type="button"
            onClick={() => adddata(0)}
            className="btn btn-primary w-50"
          >
            Add Employee
          </button> */}
        {/* ) : null} */}
      </div>
      {/* {tabtype ? <Masteradmin getData={getData}  /> : null} */}

      <div className="p-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
          {/* <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {attdata.length > itemOffset + 20 ? itemOffset + 20 : attdata.length} of {attdata.length} entries
          </div> */}
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Actions</th>
                  <th className="whitespace-nowrap">Date</th>
                  <th className="whitespace-nowrap">Employee Name</th>
                  <th className="whitespace-nowrap">Punch Type</th>
                  <th className="whitespace-nowrap">New Time</th>
                  <th className="whitespace-nowrap">Reason</th>
                  <th className="whitespace-nowrap">Ticket Type</th>                  
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                      <td>
                          <div className="flex lg:justify-center items-center cursor-pointer">                          
                            {attdata[item].status===0 ?<>
                            <button className="btn btn-primary text-white btn-sm" onClick={() => statuschange(attdata[item].id,1,attdata[item].ticket_type)}>
                              Approve
                            </button>
                            <button className="ml-2 btn btn-danger text-white btn-sm" onClick={() => statuschange(attdata[item].id,2,attdata[item].ticket_type)}>
                              Reject
                            </button>
                            {/* <button className="btn btn-primary btn-sm" onClick={() => editdata(attdata[item])}>
                            <FaPlus title="Create Ticket" style={{color:'white'}}  />
                            </button> */}
                            </>: attdata[item].status ===1 ?
                            <button className="btn btn-success text-white btn-sm p-1">
                              Approved
                            </button> :
                             <button className="ml-2 btn btn-danger text-white btn-sm">
                                Rejected
                            </button>
                            }
                            {/* <FaTrashAlt onClick={() => deletedata(userdata[item].uuid)} title="Delete" className="text-danger mr-2"/>                            
                            <FaSignInAlt onClick={() => signindata(userdata[item])} title={"Sign In as "+ userdata[item].name} style={{color:'#1E40AF'}} className="mr-2"/> 
                            </>:"-" } */}
                            

                          </div>
                        </td>
                        <td>{format(new Date(attdata[item].date), "yyyy-MM-dd")}</td>
                        <td>{attdata[item].user}</td>
                        {attdata[item].ticket_type==0 &&
                        <td>{attdata[item].punch_type===0?
                        "Punch In":
                        "Punch Out"
                        }</td>
                        }
                        {attdata[item].ticket_type==1 &&
                        <td>{attdata[item].punch_type===0?
                        "Break In":
                        "Break Out"
                        }</td>
                        }
                        <td>{attdata[item].punch_type===0?attdata[item].start_time:attdata[item].end_time}</td>
                        <td>{attdata[item].message}</td>
                        <td>{attdata[item].ticket_type==1 ?
                          <button className="btn btn-primary text-white btn-sm p-1" >
                              Break Ticket
                            </button> :
                            <button className="btn btn-primary text-white btn-sm  p-1">
                              Punch Ticket
                            </button>
                        }</td>
                        
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={20}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
