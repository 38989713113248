import React,{useState, useEffect} from 'react'
import swal from "sweetalert";
import { BASEURL } from "./../BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { FaCalendar } from 'react-icons/fa';
import { format } from "date-fns";
import Webcam from 'react-webcam';

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}

const JobApplicationStatus = (props) => {
    const [uuid,setuuid] = useState("");
    const [appdata,setappdata] = useState([]);
    const [currentsalary,setcurrentsalary] = useState("");
    const [salaryexp,setsalaryexp] = useState("");
    const [totalworkexp_years,settotalworkexp_years] = useState("0");
    const [totalworkexp_months,settotalworkexp_months] = useState("0");
    const [istraining,setistraining] = useState(0);
    const [fromwheretraining,setfromwheretraining] = useState("");
    const [isrelocate, setisrelocate] = useState(0);
    const [address, setAddress] = useState("");
    const [position, setposition] = useState("");
    const [skill, setskill] = useState("");
    const [education, seteducation] = useState("");
    const [date, setdate] = useState(new Date());
    const [dateval, setdateval] = useState(new Date());
    let [loading, setLoading] = useState(false); 
    const [iscapture, setiscapture] = useState(false);
    const [picture, setPicture] = useState('');
    
    const webcamRef = React.useRef(null);
    
    const capture = React.useCallback(() => {
      setiscapture(true);
      const pictureSrc = webcamRef.current.getScreenshot();
      setPicture(pictureSrc);
    }, [webcamRef]);
    
    const setRetake = React.useCallback(() => {
      setPicture('');  // Reset picture
      setiscapture(false);  // Reset capture status
    }, []);

    let navigate = useNavigate();

    useEffect(() => {
      setuuid(window.location.pathname.replace('/view-job-application-status/',''));
      getData(window.location.pathname.replace('/view-job-application-status/',''))
    }, [])

    const handledate = (e) => {
      if (e !== null) {
        setdate(e);
        setdateval(new Date(e));
      } else {
        setdateval(new Date());
      }
    };
    
    const getData = async(uuid)=>{
        const formData = new FormData()
        setLoading(true)
        formData.append('uuid',uuid)
        formData.append('type',1)
        // console.log(path);
        const res = await axios.post(BASEURL + "get-jobdata", formData, {
            headers: {
              "Content-Type": "application/json",
            },
        });

        if (res.data.status === 201) {
          if(res.data.data.length>0){
            setappdata(res.data.data);
            setposition(res.data.position);
          }
        }
        setLoading(false);
    }

   
   

    const savedata = async (e) => {
      e.preventDefault();
        if(salaryexp === ""){
              swal({
                title: "Error",
                text: "Please Enter value all Required value",
                icon: "error",
                timer: 2000,
              });
              return;
        }         
       let data = {
        "uuid": uuid,
        "currentsalary":currentsalary,
        "salaryexp":salaryexp,
        "totalworkexp_years":totalworkexp_years,
        "totalworkexp_months":totalworkexp_months,
         "istraining": istraining,
         "fromwheretraining":fromwheretraining, 
         "isrelocate":isrelocate,
         "skill" : skill,
         "education": education,
         "address": address,
         "dob" : dateval,
         "picture" : picture
       }
        // console.log(); return
        swal({
          title: "Submit application details",
          text: `Are you sure you want to Submit application details? You can't change after submit application`,
          buttons: ["No", "Yes"],
          showCloseButton: true,
        }).then((willDelete) => {
          if (willDelete) {
            finalsubmitform(data)
          }
        });       
        
      };

  
      // console.log(quedata);    
    const finalsubmitform = async(data) => {
        console.log(data);
        // console.log(uuid); return
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        // await fetch(BASEURL + `job-application-save/`, {
        //   method: "POST",
        //   headers: myHeaders,
        //   body: JSON.stringify({
        //     data,
        //   }),
        // })
        //   .then((response) => response.json())
        //   .then((res) => {
        //     if (res.status === 201) {
        //       swal({
        //         title: "Success",
        //         text: res.message,
        //         icon: "success",
        //         timer: 2000,
        //       });
        //       getData(uuid);             
        //     }
        //   })
        //   .catch((err) => console.log(err));
        // setLoading(false);
    }


  return ( <>
    <div className="p-0">
    <div className="container " style={{
          height: "100vh",
          overflow: "scroll"
    }}>
      <div className="block xl:grid grid-cols-2 ">
        <div className="hidden xl:flex flex-col min-h-screen ">
          <div class='fixed'>
          <div className="-intro-x flex items-center pt-5">
            <img
              alt="Midone - HTML Admin Template"
              className="w-6"
              src="../images/logo.svg"
            />
            <span className="text-white text-lg ml-3">Jenya Tracking</span>
          </div>
          <div className="mt-5 pt-5">
            <img alt='' className="-intro-x w-1/2 mt-5 pt-5" src="../images/illustration.svg"/>
            <div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
              A few more clicks to
              <br />
              submit your job application form
            </div>         
          </div>
          </div>
        </div>

        <div className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0" style={{overflow:"scroll"}}>
          <div className="">
            <div className="grid grid-cols-24 gap-12 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="text-base mr-auto" style={{fontSize:"15px",fontWeight:500}}>
                                {/* Job Application - Fill below form details for further rounds */}
                                Job Application
                                 {/* - {appdata[0]?.name} */}
                                </h2>                               
                            </div>
                            <div id="form-validation" className="p-5">
                            {/* {appdata[0]?.status} */}
                                  {appdata[0]?.status!==1 ? 
                                  <div className="preview">                                 
                                    <div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
                                    {loading}
                                    {loading ? (
                                      <HashLoader
                                        color="#5755d9"
                                        size={30}
                                        style={{ position: "absolute", left: "10%",paddingTop: "10%" }}
                                      />
                                    ) : 
                                    <form className='pb-5 mb-5' onSubmit={(e) => savedata(e)}>

                                    <div className="grid grid-cols-12 gap-x-5 text-left mb-3">                                       
                                       <div className="col-span-12 2xl:col-span-6 pb-2">
                                       <label htmlFor="update-profile-form-2" className="form-label">
                                          Name:
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              value={appdata[0]?.name}
                                              readOnly
                                          ></input>
                                       </div>                                
                                       <div className="col-span-12 2xl:col-span-6 pb-2">
                                       <label htmlFor="update-profile-form-2" className="form-label">
                                          Position:
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              value={position[0]?.name}
                                              readOnly
                                          ></input>
                                       </div>                                
                                    </div>
                                        <hr />
                                      <div className="grid grid-cols-12 gap-x-5 text-left mt-3">                                       
                                       
                                      <div className="col-span-6 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          What is your total work experience? <br /> In Years
                                          </label> 
                                          <br />
                                          <select name="years" className='form-control mt-0' onChange={(e)=>{settotalworkexp_years(e.target.value)}}>
                                                <option selected="selected" value="0">0</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8+">8+</option>
                                            </select>                                          
                                          </div>
                                     
                                      <div className="col-span-6 2xl:col-span-6 pb-2"> <br />
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          In Months
                                          </label>
                                          <br />
                                            <select name="months" className='form-control mt-0' onChange={(e)=>{settotalworkexp_months(e.target.value)}}>
                                            <option selected="selected" value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                        </select>
                                          </div>
                                       
                                        {(totalworkexp_years!=="0" || totalworkexp_months!=="0") && 
                                        <div className="col-span-12 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          What is Your Current Salary?
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="currentsalary"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setcurrentsalary(e.target.value)}
                                              value={currentsalary}
                                          ></input>
                                          </div>
                                        }
                                          <div className="col-span-12 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          What is Your Salary Expectations?
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="salaryexp"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setsalaryexp(e.target.value)}
                                              value={salaryexp}
                                          ></input>
                                          </div>

                                          <div className="col-span-12 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Skill
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="skill"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setskill(e.target.value)}
                                              value={skill}
                                          ></input>
                                          </div>

                                          <div className="col-span-12 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Educations
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="education"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>seteducation(e.target.value)}
                                              value={education}
                                          ></input>
                                          </div>

                                          <div className="col-span-12 2xl:col-span-6 pb-2">
                                            <label htmlFor="update-profile-form-2" className="form-label">
                                              Date Of Birth
                                            </label>
                                            <br />
                                            <DatePicker
                                              clearIcon=""
                                              calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
                                              onChange={handledate}
                                              value={dateval}
                                            />
                                          </div>
                                        
                                        <div className="col-span-12 2xl:col-span-12 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Have you done training?
                                          </label>
                                          <br />
                                          <select
                                              name="istraining"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setistraining(e.target.value)}
                                              value={istraining}
                                          >
                                            <option value="0">No</option>
                                            <option value="1">Yes</option>
                                          </select>
                                          </div>
                                  
                                      
                                      {istraining==="1" &&
                                        <div className="col-span-12 2xl:col-span-12 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          From Where did you taken training?
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="fromwheretraining"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setfromwheretraining(e.target.value)}
                                              value={fromwheretraining}
                                          ></input>
                                          </div>
                                        }
                                          <div className="col-span-12 2xl:col-span-12 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Are You Willing to Relocate to Gandhinagar?
                                          </label>
                                          <br />
                                          <select
                                              name="isrelocate"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setisrelocate(e.target.value)}
                                              value={isrelocate}
                                          >
                                           <option value="0">Yes</option>
                                            <option value="1">No</option>
                                          </select>
                                          </div>
                                          
                                          <div className="col-span-12 2xl:col-span-6 pb-2">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Address
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              name="address"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setAddress(e.target.value)}
                                              value={address}
                                          ></input>
                                          </div>

                                          <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                                          <div>
                                            <label htmlFor="update-profile-form-1" className="form-label">
                                              Upload Image:
                                            </label>
                                            <div className="container">
                                              {(!iscapture || picture === '') ? (
                                                <Webcam
                                                  audio={false}
                                                  height={200}
                                                  ref={webcamRef}
                                                  width={200}
                                                  screenshotFormat="image/jpeg"
                                                  videoConstraints={videoConstraints}
                                                />
                                              ) : (
                                                <img src={picture} alt="Captured" />
                                              )}

                                              {picture !== '' ? (
                                                <button
                                                  type='button'
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setRetake();
                                                  }}
                                                  className="btn btn-primary"
                                                >
                                                  Retake Image
                                                </button>
                                              ) : (
                                                <button
                                                  type='button'
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    capture();
                                                  }}
                                                  className="btn btn-danger"
                                                >
                                                  Capture Image
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      
                                      <div className="text-left">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-50 mt-3 mb-5"
                                      >
                                        Submit Application
                                      </button> 
                                      </div>        
                              
                                    </form>
                                  }
                                    </div>                                
                                 </div>
                                 :<>
                                 <div className="preview">                                 
                                    <div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
                                    <div className="grid grid-cols-12 gap-x-5 text-left">                                       
                                       <div className="col-span-12 2xl:col-span-12 pb-2">
                                       <label htmlFor="update-profile-form-2" className="form-label">
                                          Name:
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              value={appdata[0]?.name}
                                              readOnly
                                          ></input>
                                       </div>                                
                                    </div>
                                  
                                    <div className="grid grid-cols-12 gap-x-5 text-left">                                       
                                       <div className="col-span-12 2xl:col-span-12 pb-2">
                                       <label htmlFor="update-profile-form-2" className="form-label">
                                          Position:
                                          </label>
                                          <br />
                                          <input
                                            type='text'
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              value={position[0]?.name}
                                              readOnly
                                          ></input>
                                       </div>                                
                                    </div>
                                    <div className="grid grid-cols-12 gap-x-5 text-left mt-3">                                       
                                       <div className="col-span-12 2xl:col-span-12 pb-2">
                                       <label htmlFor="update-profile-form-2" className="form-label">
                                          Interview Status:
                                          </label>
                                          <br />
                                          {appdata[0]?.status===1 ? 
                                          <button className='btn btn-primary'>In Review</button>
                                          :<button className='btn btn-danger btn-sm'>Hold</button> }
                                       </div>                                
                                    </div>
                                    </div>
                                 </div>
                                 </> }                                
                            </div>
                        
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>



</>

  )
}

export default JobApplicationStatus