import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import Masteradmin from "../Masteradmin";

const AddEmployee = () => {
	const [userdata, setuserdata] = useState([]);
	const [dept, setdept] = useState(null);
	const [bkpdata, setbkpdata] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [currentItems, setCurrentItems] = useState(null);
	const [items, setitems] = useState([]);
	const [errors, setErrors] = useState({});

	const [add, setadd] = useState(2);
	let [tabtype, settabtype] = useState(false);

	const [getdata, setgetdata] = useState([]);
	const user_data = JSON.parse(localStorage.getItem("isAuth"));
	const comp_uuid = user_data.companydata[0].uuid;
	const [data, setdata] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		organization: comp_uuid,
		email: "",
		password: "",
		status: true,
		uuid: ``,
	});

	const [isadd, setisadd] = useState(true);
	let [loading, setLoading] = useState(false);
	let [msg, setmsg] = useState({
		type: "",
		msg: "",
	});

	let [master] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

	useEffect(() => {
		setadd(JSON.parse(localStorage.getItem("isAuth")).type);
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
		getUserData();
		getdepartments();
		getData();
	}, []);
	useEffect(() => {
		const endOffset = itemOffset + 10;
		setCurrentItems(items.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(items.length / 10));
	}, [itemOffset]);

	const getdepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `getAllDepartments/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setdept(res.data);
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));
	};

	const getData = async () => {
		settabtype(false);
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		let data = await fetch(BASEURL + `addorganization`, {
			method: "GET",
			headers: myHeaders,
		});
		let data2 = await data.json();
		setgetdata(data2.data);
		setLoading(false);
	};
	const getUserData = async (e = "") => {
		let isactive = 1;
		if (e !== "") {
			isactive = e.target.value;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		// console.log(master);
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-Employee/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				listtype: 1,
				role: 1,
				master,
				isactive,
				uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
					.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					let arr = [];
					let arr1 = [];
					for (const key in res.data) {
						arr.push(key);
						if (key < 9) {
							arr1.push(key);
						}
					}
					setitems(arr);
					setCurrentItems(arr1);
					setPageCount(Math.ceil(arr.length / 10));
					setuserdata(res.data);
					console.log("response coming to the uuid", res.data.uuid);
					console.log("Response", res.data);
					setbkpdata(res.data);
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	const handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		setdata({
			...data,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handlechange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setdata({
			...data,
			[e.target.name]: value,
		});
		// console.log(data);
	};
	// let user_data = JSON.parse(localStorage.getItem("isAuth"));
	// let comp_uuid = user_data.companydata[0].uuid;
	console.log("uuid from locasl", comp_uuid);
	const savedata = async (elm) => {
		if (
			data.name === "" ||
			data.contactno === "" ||
			data.email === "" ||
			(!master && data.organization === "")
		) {
			swal({
				title: "Error",
				text: "Please Enter Required Values",
				icon: "error",
				timer: 2000,
			});
			return;
		}

		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		const organizationUUID = master
			? "abc3bab0-2b6e-4fb7-a453-4049c8157ac5"
			: userdata.companydata[0].uuid;

		await fetch(BASEURL + `add-user/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				company_uuid: organizationUUID,
				company: userdata,
				data: {
					...data,
					company_uuid: organizationUUID, // Add company_uuid to data
				},
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					userdata.companydata[0].name = data.name;
					userdata.companydata[0].support_access = data.supportaccess;
					localStorage.setItem("isAuth", JSON.stringify(userdata));

					setmsg({ type: res.type, msg: res.message });
					getUserData();
					setdata({
						name: "",
						department: 1,
						hod: 2,
						password: "",
						contactno: "",
						email: "",
						status: true,
					});

					swal({
						title: "Success",
						text: res.message,
						icon: "success",
						timer: 2000,
					});

					setTimeout(() => {
						setmsg({ type: "", msg: "" });
						setisadd(!isadd);
					}, 1000);
				} else {
					swal({
						title: "Error",
						text: res.message,
						icon: "error",
						timer: 2000,
					});
				}
			})
			.catch((err) => console.log(err));

		setLoading(false);
	};

	// const savedata = async (elm) => {
	// 	if (
	// 		data.name === "" ||
	// 		data.contactno === "" ||
	// 		data.email === "" ||
	// 		(!master && data.organization === "")
	// 	) {
	// 		swal({
	// 			title: "Error",
	// 			text: "Please Enter Required Values",
	// 			icon: "error",
	// 			timer: 2000,
	// 		});
	// 		return;
	// 	}

	// 	setLoading(true);
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	myHeaders.append("Content-Type", "application/json");

	// 	const organizationUUID = master
	// 		? "abc3bab0-2b6e-4fb7-a453-4049c8157ac5"
	// 		: userdata.companydata[0].uuid;

	// 	await fetch(BASEURL + `add-user/`, {
	// 		method: "POST",
	// 		headers: myHeaders,
	// 		body: JSON.stringify({
	// 			company_uuid: organizationUUID,
	// 			company: userdata,
	// 			data,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status === 201) {
	// 				userdata.companydata[0].name = data.name;
	// 				userdata.companydata[0].support_access = data.supportaccess;
	// 				localStorage.setItem("isAuth", JSON.stringify(userdata));

	// 				setmsg({ type: res.type, msg: res.message });
	// 				getUserData();
	// 				setdata({
	// 					name: "",
	// 					department: 1,
	// 					hod: 2,
	// 					password: "",
	// 					contactno: "",
	// 					email: "",
	// 					status: true,
	// 				});

	// 				swal({
	// 					title: "Success",
	// 					text: res.message,
	// 					icon: "success",
	// 					timer: 2000,
	// 				});

	// 				setTimeout(() => {
	// 					setmsg({ type: "", msg: "" });
	// 					setisadd(!isadd);
	// 				}, 1000);
	// 			} else {
	// 				swal({
	// 					title: "Error",
	// 					text: res.message,
	// 					icon: "error",
	// 					timer: 2000,
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => console.log(err));

	// 	setLoading(false);
	// };
	const adddata = (data) => {
		settabtype(false);
		setisadd(!isadd);
		setdata({
			name: "",
			department: 1,
			hod: 2,
			password: "1",
			contactno: "",
			email: "",
			status: true,
		});
	};
	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">Add Employee </h2>
				{add === 0 ? (
					<button
						type="button"
						onClick={() => adddata(0)}
						className="btn btn-primary w-50"
					>
						Add Employee
					</button>
				) : // <button
				// 	type="button"
				// 	onClick={() =>
				// 		window.open(
				// 			"/employees/Addemployee",
				// 			"_blank",
				// 			"noopener,noreferrer"
				// 		)
				// 	}
				// 	className="btn btn-primary w-50"
				// >
				// 	Add Employee
				// </button>
				null}
			</div>
			{tabtype ? <Masteradmin getData={getData} /> : null}

			<div className="p-5">
				<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
					{isadd === true ? (
						<div className="flex-1 mt-6 xl:mt-0">
							<form>
								<div className="grid grid-cols-12 gap-x-5">
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div>
											<label
												htmlFor="update-profile-form-1"
												className="form-label"
											>
												Employee Name{" "}
												<span className="text-danger">
													(*)
												</span>
											</label>
											<input
												id="update-profile-form-1"
												value={data.name}
												required
												type="text"
												name="name"
												className="form-control"
												onChange={handlechange}
											/>
										</div>
									</div>
									{master !== true ? (
										<>
											{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Select Department
												</label>
												<select
													className="form-select"
													name="department"
													onChange={handlechange}
													value={data.department}
												>
													<option key={-1} value="0">
														All department
													</option>
													{dept &&
														dept.length > 0 &&
														dept.map((item, i) => {
															return (
																<option
																	key={i}
																	value={
																		item.department_id
																	}
																>
																	{
																		item.deptname
																	}
																</option>
															);
														})}
												</select>
											</div>
										</>
									) : null}
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Role{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select"
											name="hod"
											onChange={handlechange}
											value={data.hod}
										>
											{/* {master === 0 && ( */}
											<>
												<option value="2">
													Employee
												</option>
												<option value="1">
													Manager
												</option>
											</>
											{/* )} */}
											<option value="0">Admin</option>
										</select>
									</div>
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Contact Number{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.contactno}
											required
											minLength={10}
											type="number"
											name="contactno"
											className="form-control"
											onChange={handlechange}
										/>
									</div>
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-birth"
											className="form-label"
										>
											Date of Birth{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-birth"
											value={data.dob}
											required
											type="date"
											name="dob"
											className="form-control"
											onChange={handlechange}
											aria-describedby="dob-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.dob && (
											<span
												id="dob-error"
												className="text-danger"
											>
												Please enter a valid date of
												birth.
											</span>
										)}
									</div>
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3"> */}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
											aria-describedby="date-of-joining-error"
										/>
										{/* Error message, conditionally rendered */}
										{errors.date_of_joining && (
											<span
												id="date-of-joining-error"
												className="text-danger"
											>
												Please enter a valid joining
												date.
											</span>
										)}
									</div>
									{/* <div className="col-span-12 xl:col-span-6 mt-4 flex flex-col items-start space-y-2">
										<label
											htmlFor="update-profile-form-1"
											className="form-label text-sm font-medium w-full text-left text-gray-700"
										>
											Select Department
											<span className="text-danger">
												(*)
											</span>
										</label>
										<select
											className="form-select p-3 rounded-md border-gray-300 focus:ring-2 focus:ring-indigo-500"
											name="department"
											onChange={handleChange}
											value={data.department}
										>
											<option key={-1} value="0">
												All Departments
											</option>
											{dept &&
												dept.length > 0 &&
												dept.map((item) => (
													<option
														key={item.department_id}
														value={
															item.department_id
														}
													>
														{item.deptname}
													</option>
												))}
										</select>
									</div> */}
									{/* <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
										<label
											htmlFor="date-of-joining"
											className="form-label"
										>
											Date of Joining{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="date-of-joining"
											value={data.date_of_joining}
											required
											type="date"
											name="date_of_joining"
											className="form-control"
											onChange={handlechange}
										/>
									</div> */}
									{master === 1 ? null : (
										<>
											<div className="col-span-12 md:col-span-6 mt-3 mb-3">
												<label
													htmlFor="update-profile-form-2"
													className="form-label"
												>
													Organization{" "}
													<span className="text-danger">
														(*)
													</span>
												</label>
												<select
													name="organization"
													value={data.organization}
													required
													className="form-select"
													onChange={handlechange}
												>
													<option value="" key={-1}>
														Select Organization
													</option>
													{getdata.length > 0 &&
														getdata.map(
															(ele, i) => (
																<option
																	value={
																		ele.uuid
																	}
																	key={i}
																>
																	{ele.name}
																</option>
															)
														)}
												</select>
											</div>
										</>
									)}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<label
											htmlFor="update-profile-form-2"
											className="form-label"
										>
											Email Address{" "}
											<span className="text-danger">
												(*)
											</span>
										</label>
										<input
											id="update-profile-form-1"
											value={data.email}
											type="email"
											required
											name="email"
											className="form-control"
											onChange={handlechange}
										/>
									</div>{" "}
									<div className="col-span-12 md:col-span-6 mt-3 mb-3">
										<div className="">
											<label>Status</label>
											<div className="form-switch mt-2">
												{loading ? (
													<div className="text-center m-5 p-5">
														<HashLoader
															color="#5755d9"
															size={30}
															style={{
																position:
																	"absolute",
																right: "50%",
															}}
														/>
													</div>
												) : data &&
												  data.status === false ? (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														className="form-check-input"
													/>
												) : (
													<input
														type="checkbox"
														name="status"
														onChange={handlechange}
														value={data.status}
														checked
														className="form-check-input"
													/>
												)}
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => savedata()}
									className="btn btn-primary w-20 mt-3"
								>
									Save
								</button>
								<button
									type="button"
									onClick={() => adddata(1)}
									className="btn btn-danger w-20 mt-3 ml-2"
								>
									Cancel
								</button>
								<br />
								<br />
								<span
									className={
										msg.type === "success"
											? "text-success"
											: "text-danger"
									}
								>
									{msg.msg ? msg.msg : null}
								</span>
							</form>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AddEmployee;
