import { useEffect, useState } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { useNavigate } from "react-router-dom";
const GetEmployeeData = () => {
	const { uuid } = useParams();
	const navigate = useNavigate();
	const [employeeData, setEmployeeData] = useState(null);
	const [errors, setErrors] = useState({});
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(true);
	const [dept, setDept] = useState(null);
	const [data, setData] = useState({
		name: "",
		department: 1,
		hod: 2,
		contactno: "",
		email: "",
		status: true,
		uuid: ``,
		dob: "",
		date_of_joining: "",
	});

	const handleChange = (e) => {
		let value = e.target.value;
		if (e.target.name === "status") {
			value = e.target.checked;
		}
		setData({
			...data,
			[e.target.name]: value,
		});
	};

	const getDepartments = async () => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		try {
			const response = await fetch(BASEURL + `getAllDepartments/`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({
					uuid: userdata.companydata[0].uuid,
				}),
			});
			const res = await response.json();
			if (res.status === 201) {
				setDept(res.data);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching departments:", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		getDepartments();
	}, []);

	const getEmployeeData = async () => {
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));

		if (userdata && userdata.usertoken) {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		setLoading(true);
		try {
			const response = await fetch(BASEURL + `get-employee`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify({ uuid }),
			});
			const res = await response.json();
			if (res.status === 201) {
				const empData = res.data[0];
				setEmployeeData(empData);
				setData((prevData) => ({
					...prevData,
					name: empData.name,
					department: empData.department,
					hod: empData.type,
					contactno: empData.contact_no,
					email: empData.email,
					dob: empData.dob ? empData.dob.split("T")[0] : "",
					date_of_joining: empData.date_of_joining
						? empData.date_of_joining.split("T")[0]
						: "",
					status: empData.status,
				}));
			} else {
				setEmployeeData(null);
				setError("No data available");
			}
		} catch (error) {
			console.error("Error fetching employee data:", error);
			setError("Error fetching data");
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getEmployeeData();
	}, [uuid]);
	const updateEmployee = async (e) => {
		e.preventDefault();
		const myHeaders = new Headers();
		const userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata && userdata.usertoken) {
			const token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");

		// Prepare payload with only modified fields
		const payload = { uuid };
		if (data.name !== employeeData.name) payload.name = data.name;
		if (data.department !== employeeData.department)
			payload.department = data.department;
		if (data.hod !== employeeData.type) payload.hod = data.hod;
		if (data.contactno !== employeeData.contact_no)
			payload.contactno = data.contactno;
		if (data.email !== employeeData.email) payload.email = data.email;
		if (data.dob !== employeeData.dob) payload.dob = data.dob;
		if (data.date_of_joining !== employeeData.date_of_joining)
			payload.date_of_joining = data.date_of_joining;
		if (data.status !== employeeData.status) payload.status = data.status;

		try {
			const response = await fetch(BASEURL + `update-employee`, {
				method: "POST",
				headers: myHeaders,
				body: JSON.stringify(payload),
			});
			const res = await response.json();
			if (res.status === 201) {
				alert("Employee updated successfully");
				navigate.push("/admin/3");
			} else {
				alert(res.error || "Failed to update employee");
			}
		} catch (error) {
			console.error("Error updating employee data:", error);
			alert("Error updating data");
		}
	};

	return (
		<div className="intro-y box lg:mt-5">
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<h2 className="font-medium text-base mr-auto">Edit Employee</h2>
			</div>
			<div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
				<div className="col-span-12 mt-3 mb-3 w-full">
					<div className="p-5">
						<div className="flex flex-col-reverse xl:flex-row flex-col text-left">
							<form
								onSubmit={updateEmployee}
								className="grid grid-cols-12 gap-4 w-full"
							>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Employee Name{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="text"
										name="name"
										value={data.name}
										onChange={handleChange}
										className="form-control"
										required
									/>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Select Department
									</label>
									<select
										name="department"
										onChange={handleChange}
										value={data.department}
										className="form-select"
									>
										<option value="0">
											All department
										</option>
										{dept &&
											dept.map((item, i) => (
												<option
													key={i}
													value={item.department_id}
												>
													{item.deptname}
												</option>
											))}
									</select>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Role{" "}
										<span className="text-danger">(*)</span>
									</label>
									<select
										name="hod"
										onChange={handleChange}
										value={data.hod}
										className="form-select"
									>
										<option value="2">Employee</option>
										<option value="1">Manager</option>
										<option value="0">Admin</option>
									</select>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Contact Number{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="number"
										name="contactno"
										value={data.contactno}
										className="form-control"
										minLength={10}
										onChange={handleChange}
									/>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Date of Birth{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="date"
										name="dob"
										value={data.dob}
										className="form-control"
										onChange={handleChange}
									/>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Date of Joining{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="date"
										name="date_of_joining"
										value={data.date_of_joining}
										className="form-control"
										onChange={handleChange}
									/>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Email Address{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="email"
										name="email"
										value={data.email}
										className="form-control"
										onChange={handleChange}
									/>
								</div>
								<div className="col-span-12 md:col-span-6">
									<label className="form-label">
										Status{" "}
										<span className="text-danger">(*)</span>
									</label>
									<input
										type="checkbox"
										name="status"
										checked={data.status}
										onChange={handleChange}
										className="form-check-input"
									/>
								</div>
								<div className="col-span-12 text-right">
									<button
										type="submit"
										className="btn btn-primary"
									>
										Update
									</button>
								</div>
							</form>
						</div>
						{loading && (
							<div className="w-full text-center">
								<HashLoader color="#123abc" loading={loading} />
							</div>
						)}
						{error && <p className="text-danger">{error}</p>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GetEmployeeData;
