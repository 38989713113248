import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaFileCsv } from "react-icons/fa";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import { ReactTabulator, reactFormatter } from "react-tabulator";
// import Select from "react-select/base";
import Select from "react-select";
import axios from "axios";

const Summary = () => {
	const loadingContext = useLoadingContext();
	let today = new Date();
	let [loading, setLoading] = useState(false);
	const [attdata, setattdata] = useState(null);
	const [alluser, setalluser] = useState(null);
	const [date, setdate] = useState(new Date());
	const [userdatalist, setuserdata] = useState([]);
	const [orgval, setorgval] = React.useState([]);
	const [weekdata, setWeekData] = useState([]);
	const curryear = today.getFullYear();
	let month = [];
	let monthNumber = month[today.getMonth()];
	const currmonth = monthNumber;

	const [selectedmonth, setselectedmonth] = useState({
		year: curryear,
		month: Number(currmonth),
	});
	const [selectedorg, setselectedorg] = React.useState(
		JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid
	);
	// const username = "";
	const [username, setusername] = useState();
	const [summary, setSummary] = useState([]);
	const [csvData, setcsvData] = useState([]);
	const [weeksummary, setWeekSummary] = useState([]);
	const [userOptions, setUserOptions] = useState([]);
	const [selectedval, setselectedval] = useState("");
	// const [selectedVal, setSelectedVal] = useState(null);
	const [currdate] = useState(new Date());
	const [value, onChange] = useState([
		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
		new Date(),
	]);
	const [columns, setcolumn] = useState([]);

	const getSummary = async (username, date, users = []) => {
		if (
			JSON.parse(localStorage.getItem("isAuth")).type == "0" ||
			JSON.parse(localStorage.getItem("isAuth")).type == "1"
		) {
			if (username != "" && users && users.length > 0) {
				let res = users.filter((el) => {
					return Number(username) === Number(el.username);
				});
				setselectedval({
					value: username,
					uuid: res[0].uuid,
					label: res[0].name,
					active: "🟢",
				});
			}
		}
		setorgval({
			value: selectedorg,
			label: JSON.parse(localStorage.getItem("isAuth")).companydata[0]
				.name,
		});
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-Summary/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				date,
				username,
				company_uuid: JSON.parse(localStorage.getItem("isAuth"))
					.companydata[0].uuid,
				// master: JSON.parse(localStorage.getItem("isAuth")).master, // Removed this line
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setusername(username);
					setSummary(res.summary);
					setWeekSummary(res.weeksummary);
					setWeekData(res.weeklydata);
				} else {
					setSummary([]);
					setWeekSummary([]);
					setWeekData([]);
				}
			})
			.catch((err) => console.log(err));
		setLoading(false);
	};

	const filterByReference2 = (arr1, arr2) => {
		let res = [];
		res = arr1.filter((el) => {
			return arr2.find((element) => {
				return Number(element.username) === Number(el.username);
			});
		});
		return res;
	};
	let filterarr = filterByReference2(userdatalist, alluser);
	let filterarr2 = filterByReference2(userdatalist, alluser);

	if (filterarr2 && filterarr2.length > 0) {
		for (let i = 0; i < filterarr2.length; i++) {
			filterarr2[i].active2 = true;
		}
	}

	let finalarr3 = filterarr2.concat(filterarr);
	let data = [{ value: "", label: "Select All", active: "" }];
	for (let i = 0; i < finalarr3.length; i++) {
		data.push({
			value: finalarr3[i].username,
			label: finalarr3[i].name,
			active: finalarr3[i].active2 ? "🟢" : "🔴",
			uuid: finalarr3[i].uuid,
		});
	}

	const handleuser = (e) => {
		setselectedval(e);

		setusername(e.value);
		getData({
			year: selectedmonth?.year,
			month: selectedmonth?.month,
			uuid: e.uuid,
		});
	};

	const getUserData = async (org = "") => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata !== "00:00:00") {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `admin-getAllUsers/`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				type: 1,
				// uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
				uuid: org !== "" ? org : selectedorg,
				usertype: JSON.parse(localStorage.getItem("isAuth")).type,
				department: JSON.parse(localStorage.getItem("isAuth"))
					.department,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				if (res.status === 201) {
					setuserdata(res.data);
					// console.log(res.data);
					setalluser(res.alluser);
					if (res.alluser.length > 0 && res.data.length > 0) {
						setusername(res.alluser[0]["username"]);
						getSummary(res.alluser[0]["username"], date, res.data);
					} else {
						let userdata = JSON.parse(
							localStorage.getItem("isAuth")
						);
						getSummary(userdata.username, date, res.data);
					}
				}
				setLoading(false);
			})
			.catch((err) => console.log(err));

		loadingContext.done();
	};

	useEffect(() => {
		getUserData();
	}, []);

	const handleUserChange = (selectedOption) => {
		setselectedval(selectedOption);
	};

	useEffect(() => {
		getData(
			username,
			format(new Date(value[0]), "yyyy-MM-dd"),
			format(new Date(value[1]), "yyyy-MM-dd")
		);
	}, []);

	const options = {
		pagination: "local",
		paginationSize: 10,
	};

	function DetailVal(props) {
		const rowData = props.cell._cell.row.data;

		if (rowData.employee !== "") {
			return (
				<a
					href={"/employees/" + rowData.uuid}
					rel="noreferrer"
					target="_blank"
					alt=""
				>
					<u>{rowData.name}</u>
				</a>
			);
		} else {
			return "No data Available";
		}
	}

	const getData = async (username, date, ldate) => {
		setLoading(true);
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		myHeaders.append("Content-Type", "application/json");
		await fetch(BASEURL + `get-monthwise-attreport`, {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify({
				username,
				date,
				ldate,
				uuid: userdata?.companydata[0]?.uuid,
			}),
		})
			.then((response) => response.json())
			.then((res) => {
				let columns = [
					{
						title: "Employee",
						field: "name",
						formatter: reactFormatter(<DetailVal />),
						width: 220,
					},
					{ title: "Username", field: "username", width: 120 },
				];
				columns.push(
					{ title: "Present", field: "present", width: 140 },
					{ title: "Absent", field: "absent", width: 140 },
					{ title: "Leaves", field: "leaves", width: 140 },
					{ title: "Halfday", field: "halfdayleaves", width: 140 },
					{ title: "Holidays", field: "holidays", width: 140 }
				);
				if (res.status === 201) {
					setattdata(res.data);

					res.datearr.forEach((element) => {
						columns.push({
							title: format(new Date(element), "dd E"),
							field: element,
							width: 140,
						});
					});

					setcsvData([...res.reparr]);
				} else {
					setattdata([
						{
							name: "No data Available",
						},
					]);
				}
				setcolumn(columns);
			})
			.catch((err) => console.log(err));

		setLoading(false);
		loadingContext.done();
	};

	const handledate = (e) => {
		onChange(e);
		const savedata = getData(
			username,
			format(new Date(e[0]), "yyyy-MM-dd"),
			format(new Date(e[1]), "yyyy-MM-dd")
		);
		localStorage.setItem("checkdate", savedata);
	};

	return (
		<>
			<div className="grid grid-cols-12 gap-6">
				<div className="col-span-12 2xl:col-span-12">
					<div className="intro-y flex flex-col sm:flex-row items-center mt-8">
						<h2 className="text-lg font-medium mr-auto">
							Monthly Attendance Report
						</h2>

						<Select
							value={selectedval}
							className="basic-single mr-2"
							onChange={(e) => handleuser(e)}
							options={data}
							getOptionLabel={(option) =>
								` ${option.active} ${option.label}`
							}
						/>

						{/* <Select
							value={selectedVal}
							onChange={handleUserChange}
							options={userOptions}
							getOptionLabel={(option) =>
								`${option.active ? "Active" : "Inactive"} - ${
									option.label
								}`
							}
							className="basic-single mr-2"
							styles={{
								option: (base) => ({
									...base,
									height: "100%",
									display: "flex",
								}),
							}}
						/> */}
						<div className="ml-auto flex items-center text-primary">
							<DateRangePicker
								clearIcon=""
								calendarIcon={
									<FaCalendar
										style={{ color: "rgb(30 64 175)" }}
									/>
								}
								className="form-control"
								onChange={handledate}
								value={value}
							/>
							<CSVLink
								filename={
									"All-Employees-Monthly-Attendance.csv"
								}
								className="ml-3 mr-3"
								data={csvData}
							>
								<FaFileCsv
									style={{
										color: "rgb(30 64 175)",
										fontSize: "25px",
									}}
								/>
							</CSVLink>
						</div>
					</div>
					{loading === true ? (
						<div
							className="mt-5 "
							style={{ display: "-webkit-inline-box" }}
						>
							<HashLoader color="#5755d9" size={30} />
						</div>
					) : (
						<div className="intro-y">
							<div className="overflow-x-auto scrollbar-hidden">
								{attdata ? (
									<ReactTabulator
										columns={columns}
										data={attdata}
										options={options}
										className="mt-5 table-report table-report--tabulator"
									/>
								) : (
									<div className="text-danger text-left mt-5 mb-5">
										No Data Available
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Summary;
